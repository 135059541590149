import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router"
import {client} from '../utils'

const qs = require('qs');

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: "1.0",
    LoggingIn: false,
    accessToken: null,
    loginError: null,
    currentUser: null,
    isAdmin: null,
    userList: null,
    knownSites: null,
    scopes: null,
    refreshRate: 60000,
    sites: null,
    navTree: false,
    lastUpdate: null,
    selectedItems: [],
    loading: false,
    mapViewLocked: false,
    mapStyle: 'osm-liberty'
  },
  mutations: {
    initStore: state => {
      // initialise le store avec le localstorage
      if (localStorage.getItem('accessToken')) {
        state.accessToken = localStorage.getItem('accessToken')

        if (localStorage.getItem('selectedItems')) {
          state.selectedItems = JSON.parse(localStorage.getItem('selectedItems'))
        }
        if (localStorage.getItem('mapStyle')) {
          state.mapStyle = JSON.parse(localStorage.getItem('mapStyle'))
        }
      }
    },
    LogoutUser: state => {
      // suppression du token dans le navigateur
      localStorage.removeItem('accessToken')
      localStorage.removeItem('currentUser')
      localStorage.removeItem('selectedItems')
      localStorage.removeItem('mapStyle')
      state.accessToken = null
      state.currentUser = null
      state.selectedItems = []
      state.mapStyle = 'osm-liberty'
    },
    loginStart: state => state.LoggingIn = true,
    loginStop: (state, errorMessage) => {
      state.LoggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
      localStorage.setItem('accessToken', accessToken);
    },
    updateCurrentUser: (state, user) => {
      state.currentUser = user,
      state.isAdmin = state.currentUser.admin
    },
    updateUserList: (state, users) => {
      state.userList = users
    },
    updateKnownSites: (state, sites) => {
      state.knownSites = sites
    },
    updateScopes: (state, scopes) => {
      state.scopes = scopes
    },
    updateSites: (state, sites) => {
      state.sites = sites
    },
    swapNavTree: (state, value) => {
      state.navTree = value
    },
    updateLastUpdate: (state, value) => {
      state.lastUpdate = value
    },
    updateSelectedItems: (state, value) => {
      state.selectedItems = value
      localStorage.setItem('selectedItems', JSON.stringify(value))
    },
    loadingOn: (state) => {
      state.loading = true
    },
    loadingOff: (state) => {
      state.loading = false
    },
    lockMapView: (state) => {
      state.mapViewLocked = true
    },
    unlockMapView: (state) => {
      state.mapViewLocked = false
    },
    setMapStyle: (state, style) => {
      state.mapStyle = style
      localStorage.setItem('mapStyle', JSON.stringify(style))
    }
  },
  actions: {
    doLogin({ commit }, loginData) {
      commit('loginStop', null);
      commit('loginStart');
      client.post('/token', qs.stringify(loginData))
      .then(function(response) {
        commit('updateAccessToken', response.data.access_token);
        // on chaine avec la récupération des infos utilisateur
        return client.get('/users/me',
        {headers: {"Authorization": 'Bearer ' + response.data.access_token}})
      })
      .then(function(response) {
        commit('updateCurrentUser', response.data)
        setTimeout(()=> {
          commit('loginStop', null);
          router.push({ name: 'Home' });
        }, 1000);
      })
      .catch(function(error) {
        if ('response' in error) {
          commit('loginStop', error.response.data.detail);
        } else {
          commit('loginStop', error);
        }
        commit('updateAccessToken', null);
      })
    },
    getUser({ commit }) {
      client.get('/users/me',
        {
          headers: {"Authorization": 'Bearer ' + this.state.accessToken}
        })
      .then(function(response){
        commit('updateCurrentUser', response.data)
      }).catch(function(error) {
        if (error.response && error.response.status == 401) {
          commit('LogoutUser');
          router.push({ name: 'Login' });
        }
      })
    },
    deleteUser({commit}, userid) {
      client.delete('/users/' + userid,
        {
          headers: {"Authorization": 'Bearer ' + this.state.accessToken}
        })
      .then(function(){
        this.getUsers()
      }).catch(function(error) {
        if (error.response && error.response.status == 401) {
          commit('LogoutUser');
          router.push({ name: 'Login' });
        }
      })
    },
    getSites({commit}) {
      client.get('/sites', {
        headers: {"Authorization": 'Bearer ' + this.state.accessToken}
      }).then((response) => {
          commit('updateSites', response.data)
          commit('updateLastUpdate', new Date(Date.now()).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }))
      }).catch((error) => {
        if (error.response && error.response.status == 401) {
          commit('LogoutUser');
          router.push({ name: 'Login' });
        }
      })
    },
    getKnownSites({commit}) {
      client.get('/knownsites', {
        headers: {"Authorization": 'Bearer ' + this.state.accessToken}
      }).then((response) => {
          commit('updateKnownSites', response.data)
      }).catch((error) => {
        if (error.response && error.response.status == 401) {
          commit('LogoutUser');
          router.push({ name: 'Login' });
        }
      })
    },
    async getScopes({commit}) {
      var response = await client.get('/users/scopes',{
        headers: {"Authorization": 'Bearer ' + this.state.accessToken}
      })
      commit('updateScopes', response.data)
    },
    async getUsers({commit}) {
      var response = await client.get('/users',{
        headers: {"Authorization": 'Bearer ' + this.state.accessToken}
      })
      commit('updateUserList', response.data)
    }
  },
  modules: {
  }
})
