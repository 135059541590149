<template>
  <v-container fluid class="fill-height overflow-scroll text-center d-flex">
    <v-row>
      <v-col>
        <h2 class="font-weight-regular text-center mb-6 mt-6">
          Gestion des utilisateurs
        </h2>
      </v-col>
    </v-row>
      <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
          <div class="d-flex">
              <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
                  Ajouter
              </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title>
              <span>Ajouter un nouvel utilisateur</span>
          </v-card-title>
          <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="editedItem.username" label="Login"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field v-model="editedItem.fullname" label="Nom complet"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="editedItem.email" label="E-mail"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    label="Mot de passe"
                    hint="Au moins 8 caractères"
                    counter
                    :rules="[passwordLength]"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-row><v-switch
                    v-model="editedItem.admin"
                    inset
                    label="Administrateur"
                  ></v-switch>
                </v-row>
                <v-row><v-switch
                    v-model="editedItem.hide_names"
                    inset
                    label="Anonymiser les noms"
                  ></v-switch>
                </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                      v-model="editedItem.scope"
                      :items="scopes"
                      label="Type d'utilisateur"
                      dense
                      hide-details
                    ></v-select>
                </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showNewDialog()">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="newUser(editedItem)">Sauvegarder</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-row class="fill-height overflow-hidden">
      <v-col>
        <div class="overflow-auto fill-height">
      <v-data-table
        :headers="headers"
        :items="users"
        :loading="dataTableLoading"
        class="elevation-5"
        loader-height=4
        :item-class="itemRowBackground"
        disable-pagination
        hide-default-footer
        :items-per-page="100"
      >
        <template v-slot:item.actions="{ item }">

          <!--supprimer utilisateur-->
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
              <span>Supprimer l'utilisateur {{item.username}}</span>
            </v-tooltip>

          <!--reset mot de passe-->
            <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                  smalls
                  v-on="on"
                  @click="showPWDialog(item)"
                >
                  mdi-lock-reset
                </v-icon>
            </template>
            <span>Ré-initialiser le mot de passe</span>
            </v-tooltip>

        </template>

        <!--username-->
        <template v-slot:item.username="props">
          <v-edit-dialog
            :return-value.sync="props.item.username"
            @save="save(props.item)"
            @cancel="cancel"
            @open="open"
          >
            {{ props.item.username }}
            <template v-slot:input>
              <v-text-field
                v-model="props.item.username"
                label="Edit"
                single-line
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <!--fullname-->
        <template v-slot:item.fullname="props">
          <v-edit-dialog
            :return-value.sync="props.item.fullname"
            @save="save(props.item)"
            @cancel="cancel"
            @open="open"
          >
            {{ props.item.fullname }}
            <template v-slot:input>
              <v-text-field
                v-model="props.item.fullname"
                label="Edit"
                single-line
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.scope="{ item }">
          <v-select
            v-model="item.scope"
            :items="scopes"
            dense
            solo
            hide-details
            @change="save(item)"
          ></v-select>
        </template>

        <!--email-->
        <template v-slot:item.email="props">
          <v-edit-dialog
            :return-value.sync="props.item.email"
            @save="save(props.item)"
            @cancel="cancel"
            @open="open"
          >
            {{ props.item.email }}
            <template v-slot:input>
              <v-text-field
                v-model="props.item.email"
                label="Edit"
                single-line
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

          <template v-slot:item.admin="{ item }">
            <v-simple-checkbox
              v-model="item.admin"
              :ripple="false"
              :return-value.sync="item.admin"
              v-if="item.id != currentUser.id"
              @click="save(item)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.disabled="{ item }">
            <v-simple-checkbox
              v-model="item.disabled"
              v-if="item.id != currentUser.id"
              :ripple="false"
              :return-value.sync="item.disabled"
              @click="save(item)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.hide_names="{ item }">
            <v-simple-checkbox
              v-model="item.hide_names"
              v-if="item.id != currentUser.id"
              :ripple="false"
              :return-value.sync="item.hide_names"
              @click="save(item)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.sites="{ item }">
          <v-autocomplete
              v-model="item.sites"
              :items="knownSites"
              class="autocomplete-height"
              item-text="name"
              item-value="uuid"
              label="Sites alloués"
              v-if="item.scope == 'web'"
              chips
              multiple
              hide-details
              placeholder="Start typing to Search"
              @change="save(item)"
            ></v-autocomplete>
          </template>

      </v-data-table>
      </div>
      </v-col>
    </v-row>


      <!-- nouveau mot de passe -->

      <v-dialog v-model="pwdialog" max-width="500px">
          <v-card>
            <v-card-title>
                <span>Écraser le mot de passe actuel</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="newPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      label="Mot de passe"
                      hint="Au moins 8 caractères"
                      counter
                      :rules="[passwordLength]"
                      @click:append="show1 = !show1"
                    >Nouveau mot de passe</v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="confirmPassword"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      label="Mot de passe"
                      :rules="[passwordSync, passwordLength]"
                      hint="Au moins 8 caractères"
                      counter
                      @click:append="show2 = !show2"
                    >Confirmer le mot de passe</v-text-field>
                  </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="togglePWDialog()">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="overridePassword()">Sauvergarder</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <!-- nouvel utilisateur -->
    <v-snackbar
      v-model="snack"
      :timeout="2000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {client} from '../utils'
import router from "../router"

export default {
  data() {
    return {
      dataTableLoading: true,
      users: [],
      snack: false,
      snackColor: '',
      snackText: '',
      isEditing: false,
      headers: [
        {
          text: "Login",
          align: 'start',
          value: 'username',
        },
        { text: 'Nom Complet', value: 'fullname' },
        { text: 'Type', value: 'scope', width: '80px' },
        { text: 'E-mail', value: 'email' },
        { text: 'Administrateur', value: 'admin' },
        { text: 'Anonymisation', value: 'hide_names' },
        { text: 'Désactivé', value: 'disabled' },
        { text: '', value: 'sites', width: '20%'},
        { text: 'Actions', value: 'actions', width: '15px'}
      ],
      editedItem: {admin: false, password: ''},
      dialog: false,
      pwdialog: false,
      show1: false,
      show2: false,
      newPassword: '',
      confirmPassword: '',
      passwordLength: v => (v && v.length >= 8) || 'Le mot de passe doit comporter au moins 8 caractères',
      passwordSync: v => (v && v == this.newPassword) || 'Les mots de passe de correspondent pas'
    }
  },
  computed: {
    ...mapState([
      'userList',
      'knownSites',
      'scopes',
      'currentUser',
      'accessToken'
    ])
  },
  methods: {
    ...mapActions([
      'getUsers',
      'getKnownSites',
      'getScopes',
      'deleteUser'
    ]),
    ...mapMutations([
      'updateUserList'
    ]),
    showNewDialog() {
        this.editedItem = this.defaultItem()
        this.dialog = !this.dialog
    },
    togglePWDialog() {
        this.pwdialog = !this.pwdialog
    },
    showPWDialog(item) {
        this.editedItem = item
        this.newPassword = ''
        this.confirmPassword = ''
        this.pwdialog = !this.pwdialog
    },
    defaultItem(){
      return {admin: false, password: ''}
    },
    save (item) {
        client.put('/users/' + item.id, item,
        {
            headers: {"Authorization": 'Bearer ' + this.accessToken}
        }).then(() => {
          this.updateLocaleList()
          this.snack = true
          this.snackColor = 'success'
          this.snackText = 'Sauvegardé'
          this.isEditing = false
        }).catch((error) => {
          if (error.response && error.response.status == 401) {
            this.$store.commit('LogoutUser')
            router.push({ name: 'Login' })
          } else if (error.response.status == 422) {
            // probleme de validation pydantic
            this.snack = true
            this.snackColor = 'error'
            this.snackText = error.response.data.detail[0].msg
            this.isEditing = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = error.response.data.detail
            this.isEditing = false
          }
          this.updateLocaleList()
        })
    },
    newUser (item) {
      client.post('/users', item,
      {
          headers: {"Authorization": 'Bearer ' + this.accessToken}
      }).then(() => {
        this.updateLocaleList()
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Sauvegardé'
        this.isEditing = false
        this.showNewDialog()
      }).catch((error) => {
        if (error.response && error.response.status == 401) {
          this.$store.commit('LogoutUser')
          router.push({ name: 'Login' })
        } else if (error.response.status == 422) {
          // probleme de validation pydantic
          this.snack = true
          this.snackColor = 'error'
          this.snackText = error.response.data.detail[0].msg+ ':' + error.response.data.detail[0].loc[1]
          this.isEditing = false
        } else {
          this.snack = true
          this.snackColor = 'error'
          this.snackText = error.response.data.detail
          this.isEditing = false
        }
      })
    },
    overridePassword() {
      this.editedItem.password = this.newPassword
      this.save(this.editedItem)
      this.togglePWDialog()
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Annuler'
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Édition en cours'
    },
    itemRowBackground: function (item) {
      return item.id == this.currentUser.id ? 'currentuser_back':'normal'
    },
    updateLocaleList() {
        this.dataTableLoading = true
        this.getUsers().then(() =>{
          this.users = this.userList.map(x => x)
          this.dataTableLoading = false
        })
    },
    deleteItem (item) {
      let idx = this.users.findIndex(elem => elem.id===item.id)
      if (confirm('Êtes-vous sûr de vouloir supprimer '+ item.username + ' ?')) {
        this.deleteUser(item.id)
        this.users.splice(idx, 1)
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Utilisateur supprimé'
        this.isEditing = false
      }
    },
  },
  async mounted() {
    this.dataTableLoading = true
    try {
      await this.getUsers()
      await this.getKnownSites()
      await this.getScopes()
      await new Promise(r => setTimeout(r, 200))
      //initialize user list with deep copy
      this.users = this.userList.map(x => x)
    } catch (ex) {
      this.dataTableLoading = false
      this.snack = true
      this.snackColor = 'error'
      this.snackText = ex
    } finally {
      this.dataTableLoading = false
    }
  }
}
</script>

<style>

/* table.v-table {
  max-width: 800px;
} */

.currentuser_back {
  background-color: #FFCCBC
}

.v-data-table {
  overflow: hidden; /* Empêche les barres inutiles */
}

.autocomplete-height {
  overflow-y: auto;
  overflow-x: hidden;
  height: 60px;
}

</style>
