<template>
  <v-app>
    <NavBar />
    <v-main>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
    </v-main>

    <v-footer
        color="indigo darken-4"
        dark
        clipped-right
        clipped-left
        v-if="this.$route.name == 'Login'"
      >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        class="pt-0 text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} ⋅ <strong>HyperAxess</strong>
        ⋅ Version {{version}}
      </v-col>
      <v-btn
        text
        rounded
        small
        class="my-2"
        outlined
        href="https://www.neoaxess.com/"
      >
      Powered by Neoaxess®
      </v-btn>
    </v-row>

  </v-footer>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
  name: 'App',
  components: {
    NavBar
  },
  computed: {
    ...mapState([
      'accessToken',
      'version',
    ])
  },
  methods: {
    ...mapActions([
      'getUser'
    ]),
    ...mapMutations([
      'initStore'
    ])
  },
  created() {
    this.initStore()
    if (this.accessToken) {
        // on récupère les infos de l'utilisateur via l'api
        this.getUser()
    } else if (!this.accessToken && this.$route.path != '/login') {
        this.$router.push({ name: 'Login' })
    }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
