<template>

  <div>
  <v-app-bar
    app
    color="indigo darken-4"
    dark
    clipped-right
    clipped-left
  >
    <v-progress-linear
        :active="loading"
        indeterminate
        absolute
        bottom
        color="indigo lighten-5"
    ></v-progress-linear>
    <v-btn
      icon
      large
      v-if="currentUser && isHome"
      @click="navBarTree = !navBarTree"
    >
      <v-icon class="pr-2">mdi-file-tree</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn disable text>
      <span class="gothic"><h2>HyperAxess</h2></span>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn v-if="accessToken" icon large @click="navDraw = !navDraw">
      <v-avatar
        size="46"
      ><v-icon>mdi-menu</v-icon>
      </v-avatar>
    </v-btn>

  </v-app-bar>

    <v-navigation-drawer
      floating
      disable-resize-watcher
      dark
      right
      clipped
      v-if="currentUser"
      v-model="navDraw"
      app
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon x-large>mdi-face</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{currentUser.fullname}}</v-list-item-title>
            <v-list-item-subtitle class="font-weight-light deep-orange--text" color="purple" v-if="isAdmin">Administrateur</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>


      <v-list>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-map</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item-group v-if="isAdmin">

          <v-list-item link to="/users">
            <v-list-item-icon>
              <v-icon class="deep-orange--text">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Gestion des utilisateurs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

      </v-list-item-group>

      </v-list>

  <v-spacer></v-spacer>
      <template v-slot:append>

  <v-item-group mandatory>
    <v-container>
      <v-row justify="center"
        v-for="style in styles"
        :key="style.label"
        :value="style.value">
        <v-col>
        <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? '#717171' : ''"
              class="d-flex align-center"
              height="50"
              @click="toggle"
              @click.native="changeMapStyle(style.value)"
            >
            <v-img :src="style.url" max-width="100" class="ml-3"></v-img>
              <v-scroll-y-transition>
                <div
                  v-if="active"
                  class="flex-grow-1 text-center"
                >
                  {{style.label}}
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>

        <div class="pa-6">
          <v-btn light block @click="logout">
            <v-icon left>logout</v-icon>Logout
          </v-btn>
        </div>
        <v-row
          justify="center"
          no-gutters
        >
          <v-col
            class="pt-0 text-center grey--text"
            cols="12"
          >
            {{ new Date().getFullYear() }} ⋅ <strong>HyperAxess</strong>
            ⋅ Ver {{version}}
          </v-col>
          <v-btn
            text
            rounded
            small
            class="my-2 grey--text"
            href="https://www.neoaxess.com/"
            outlined
          >
          Powered by Neoaxess®
          </v-btn>
       </v-row>
      </template>
    </v-navigation-drawer>
  </div>
</template>


<style scoped>

.gothic {
  font-family: "HandelGothic BT" !important;
}

</style>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: "NavBar",
  data () {
      return {
        navDraw: false,
        styles: [
          {'label': 'Street', 'value': 'osm-liberty', 'url': '/default.png'},
          {'label': 'Monochrome', 'value': 'positron', 'url': '/positron.png'},
          {'label': 'Satellite', 'value': 'satellite', 'url': '/satellite.png'},
        ]
      }
  },
  computed: {
    ...mapState([
      'accessToken',
      'currentUser',
      'isAdmin',
      'sites',
      'navTree',
      'version',
      'loading',
      'mapStyle'
    ]),
    mstyle:{
      get() {
        return this.mapStyle
      },
      set(value) {
        this.setMapStyle(value)
      },
    },
    navBarTree: {
      get() {return this.navTree},
      set(value) {this.swapNavTree(value)},
    },
    isHome() {
      return this.$route.name == "Home";
    }
  },
  methods: {
    ...mapMutations([
      'LogoutUser',
      'swapNavTree',
      'setMapStyle'
    ]),
    logout () {
      this.LogoutUser()
      this.$router.push({ name: 'Login' })
    },
    changeMapStyle (value) {
      this.mstyle = value
    },
  }
}
</script>
