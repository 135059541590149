<template>
    <div id="map">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="white"
          class="checkbox-overlay"
          v-bind="attrs"
          v-on="on"
          @click="zoomToExtent"
        >
          <v-icon>mdi-target</v-icon>
        </v-btn>
        </template>
        <span>Reset view to Poi extent</span>
      </v-tooltip>
            <v-btn
              rounded
              color="rgba(255, 255, 255, 0.6)"
              small
              class="map-footer"
              href="https://www.neoaxess.com/"
            >
            Powered by Neoaxess®
            </v-btn>
    </div>
</template>

<style scoped>
@import "~maplibre-gl/dist/maplibre-gl.css";

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}


.map-footer {
  z-index: 1;
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.checkbox-overlay {
  background-color: #fff;
  position: absolute;
  right: 0;
  margin-right: 9px;
  padding-right: 5px;
  top: 148px;
  z-index: 1;
  min-width: auto !important;
  width: 10px !important;
}

</style>

<script>
import {
  Map,
  FullscreenControl,
  AttributionControl,
  NavigationControl,
  ScaleControl,
  Popup
} from 'maplibre-gl'
import U from 'map-gl-utils'
import MapPopup from '@/components/Popup.vue'
import { mapState, mapMutations } from 'vuex'
import {client} from '../utils'

export default {
  name: "MapComp",
  data () {
      return {
        map: null,
        featuresExtent: null
      }
  },
  computed: {
    ...mapState([
      'accessToken',
      'selectedItems',
      'sites',
      'mapViewLocked',
      'mapStyle',
    ]),
    navTree: {
      get() {return this.$store.navTree},
      set(value) {this.$store.commit('updateNavTree', value)},
    }
  },
  methods: {
    ...mapMutations([
      'loadingOn',
      'loadingOff',
      'lockMapView'
    ]),
    getGeoJson (val) {
      this.map.U.removeSource('locations')
      if (this.selectedItems.length > 0) {
        this.loadingOn()
        client.get('/accesspoints/geojson?uuid=' + val.join('&uuid='),
        {headers: {"Authorization": 'Bearer ' + this.accessToken}}
      ).then((response) => {
        this.addGeoJSON(response.data)
      })
      setTimeout(() => {
        this.loadingOff()
      }, 1500)
    }
  },
  getAccessPointsFixed() {
      this.map.U.removeSource('locations-fixed')
      let text_color
      let text_halo_color
      if (this.mapStyle === 'satellite') {
        text_color = "rgba(255,255,255,1)"
        text_halo_color = "#334"
      } else {
        text_color = "#334"
        text_halo_color = "rgba(255,255,255,0.8)"
      }
      client.get('/accesspoints/geojson/visible',
        {headers: {"Authorization": 'Bearer ' + this.accessToken}}
        ).then((response) => {
          this.map.U.addGeoJSON('locations-fixed', response.data)
          .addSymbolLayer("ap_fixed_name",
            {
              'text-field': ['get', 'name'],
              'text-font': ['Roboto Black'],
              'text-offset': [0, 2],
              "text-anchor": "top",
              "text-allow-overlap": true,
              "text-size": 10,
              "text-color": text_color,
              "text-halo-blur": 1,
              "text-halo-width": 1,
              "text-halo-color": text_halo_color,
              "minzoom": 13
            })
          .addCircleLayer('ap_fixed_circle',
            {
                "circle-radius": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  8,
                  0,
                  15,
                  10
                ],
                "circle-color": "#1963ae",
                "circle-stroke-opacity": 1,
                "circle-opacity": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  1,
                  0,
                  13,
                  1
                ],
                "circle-stroke-width": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  8,
                  1,
                  17,
                  3
                ],
                "circle-stroke-color": "rgba(255, 255, 255, 1)",
                "circle-allow-overlap": true,
              }
            )
        })

    },
    setStyle () {
      this.map.setStyle(
        '/tiles/styles/' + this.mapStyle,
      )
      setTimeout(() => {
        this.getAccessPointsFixed()
        this.getGeoJson(this.selectedItems)
      }, 2000)
    },
    addGeoJSON (geojson) {
      // ajout de la source puis du layer si layer non vide
      if (geojson.features.length == 0) {
        this.featuresExtent = null
        return;
      }

      this.map.U.addGeoJSON('locations', geojson,
        {
          cluster: true,
          clusterProperties: {
            "person_count": ["+", ["get", "person_count"]]
          },
        }
      )
        .addSymbolLayer('locations-layer',
        {
            'icon-image': 'bluemarker',
            'icon-size': 0.7,
            'icon-anchor': 'bottom',
            'icon-allow-overlap': true,
            // 'icon-offset': ["match", ["get", "visible"], 1, ["literal", [0, 0]], ["literal", [0, 0]]],
            'text-field': ['get', 'person_count'],
            'text-font': ['Roboto Black'],
            'text-anchor': 'bottom',
            'text-offset': [0, -1],
            'filter': ['!has', 'point_count'],
        })
        .addSymbolLayer('locations-layer-clustered',
        {
            'icon-image': 'bluemarker-cluster',
            'icon-size': 0.7,
            'icon-allow-overlap': true,
            'text-field': ['get', 'person_count'],
            'text-font': ['Roboto Black'],
            'text-offset': [0, -0.1],
            'filter': ['has', 'point_count'],
        })


      this.map.on('click', 'locations-layer', (e) => {
        var coordinates = e.features[0].geometry.coordinates.slice();
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new Popup({'offset': 20, 'closeButton': false})
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .addTo(this.map);

        new MapPopup({
          propsData: { feature: e.features[0] },
          parent: this
        }).$mount('#vue-popup-content')

      });

      this.map.on('click', 'locations-layer-clustered', async (e) => {
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: ['locations-layer-clustered']
        });
        var clusterId = features[0].properties.cluster_id;
        const zoom = await this.map.getSource('locations').getClusterExpansionZoom(clusterId)
        this.map.flyTo({
          center: features[0].geometry.coordinates,
          zoom: zoom + 2
        });
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.on('mouseenter', 'locations-layer', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseenter', 'locations-layer-clustered', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'locations-layer', () => {
        this.map.getCanvas().style.cursor = '';
      });
      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'locations-layer-clustered', () => {
        this.map.getCanvas().style.cursor = '';
      });

      // store new extent
      this.featuresExtent = geojson.properties.bbox

      if (!this.mapViewLocked) {
        this.map.fitBounds(
          geojson.properties.bbox, {
            'maxZoom': 15,
            'padding': 50
          });
        // relock mapview
        this.lockMapView()
      }
    },
    zoomToExtent () {
      if (this.featuresExtent) {
        this.map.fitBounds(
          this.featuresExtent, {
            'maxZoom': 15,
            'padding': 50
          });
      }
    }
  },
  watch: {
    selectedItems: function(val) {
      //update map according to selected items
      this.getGeoJson(val)
    },
    sites: function() {
      //update map when sites changed
      this.loading = true
      setTimeout(() => {
        this.getGeoJson(this.selectedItems)
      }, 1000)
      this.loading = false
    },
    mapStyle: function() {
      this.setStyle({ diff: true })
    }
  },
  mounted() {
    this.map = new Map({
      container: "map",
      style: '/tiles/styles/'+ this.mapStyle,
      zoom: 8,
      // center on Paris
      center: [2.34, 48.85],
      attributionControl: false
    })
    U.init(this.map)

    this.map.addControl(new FullscreenControl());
    this.map.addControl(new AttributionControl({compact: true,}));
    this.map.addControl(new NavigationControl());
    this.map.addControl(new ScaleControl());
    this.map.setMaxBounds([[-19.072266,33.504759], [28.037109,62.103883]])

    this.map.on('load', async () => {
      const blue = await this.map.loadImage(require('@/assets/public/markers/png/blue.png'))
      this.map.addImage('bluemarker', blue.data)
      const bluecluster = await this.map.loadImage(require('@/assets/public/markers/png/blue-cluster.png'))
      this.map.addImage('bluemarker-cluster', bluecluster.data)
      this.getAccessPointsFixed()
    })
  }
}

</script>
